import {inject, Injectable} from '@angular/core';
import {DefinedFiltersTypes, DefinedGlobalSearchFilter, GlobalSearchQuery} from '../types';
import {ICycleQuery} from '@global-data';
import {allGlobalSearchCycleTypes} from '../consts';
import {CyclesService} from '@cycles/data-access';
import {Observable} from 'rxjs';
import {ICycleQueryResponse} from '@core/api/data-access';

@Injectable({
	providedIn: 'root',
})
export class GlobalSearchService {
	private cyclesService: CyclesService = inject(CyclesService);
	constructor() {}

	buildQueryParamsFromDefinedFilters(filters: DefinedGlobalSearchFilter[]): GlobalSearchQuery {
		if (filters.length === 0) {
			return {listType: allGlobalSearchCycleTypes};
		} else {
			let fromDate: Date = null;
			for (const filter of filters) {
				if (filter.selected) {
					if (filter.type === DefinedFiltersTypes.today) {
						const date = new Date();
						date.setDate(date.getDate() - 1);
						fromDate = date;
					}
					if (filter.type === DefinedFiltersTypes.lastWeek) {
						const date = new Date();
						date.setDate(date.getDate() - 7);
						fromDate = date;
					}
					if (filter.type === DefinedFiltersTypes.lastMonth) {
						const date = new Date();
						date.setMonth(date.getMonth() - 1);
						fromDate = date;
					}
					if (filter.type === DefinedFiltersTypes.lastQuarter) {
						const date = new Date();
						date.setMonth(date.getMonth() - 3);
						fromDate = date;
					}
					if (filter.type === DefinedFiltersTypes.lastYear) {
						const date = new Date();
						date.setFullYear(date.getFullYear() - 1);
						fromDate = date;
					}
				}
			}

			return {listType: allGlobalSearchCycleTypes, fromDate};
		}
	}

	globalSearchQuery(query: ICycleQuery): Observable<ICycleQueryResponse> {
		return this.cyclesService.query(query);
	}
}
